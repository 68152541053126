.App {
  font-family: sans-serif;
  text-align: center;
}
.imglogin {
  max-width: 150px;
}
.Btntheme {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(49, 162, 72, 1) 0%,
    rgba(151, 198, 70, 1) 100%
  );
}
img.logoDashboard {
  width: 170px;
  margin: auto;
  display: block;
  padding-top: 20px;
}
.d-none {
  display: none !important;
}
li.MuiListItem-root a {
  width: 100%;
  text-decoration: none;
  color: initial;
}
.main-custom {
  box-shadow: 0 0 16px 5px rgb(181 181 181 / 38%);
  padding: 30px 10px;
  background-color: #fcfcfc;
  border-radius: 5px;
}
.dia-lancamento > div {
  border-color: #1976d2;
  border-width: 2px;
  padding: 2px 5px;
  border-radius: 2px;
}
.valor-dia {
  border-radius: 2px;
  padding: 5px 5px;
  border-color: #1976d2;
  font-size: 18px;
  width: 100%;
}
.center-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.ac-users {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.lancamanto-box > div {
  min-width: 220px;
  margin: 5px;
}
.card-bt-custom {
  border-bottom: solid 2px #91c446;
  margin-bottom: 15px;
}
.search-btn {
  background-color: #a8cf457b !important;
}
.lancamanto-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
@media only screen and (max-width: 700px) {
  .d-fle > div {
    width: 44% !important;
  }
  .d-fle {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
  }
  .lancamanto-box > div {
    width: 100%;
    margin-bottom: 8px;
    padding: 0px 5px;
  }
  .box-search div {
    margin-bottom: 4px;
    min-width: 40px;
    text-align: center;
  }
  .box-search {
    justify-content: space-around;
    flex-wrap: wrap;
  }
}
.max-h-table{
  max-height: 800px;
  overflow:auto;
}
.Table-pay {
  border-collapse: collapse;
  width: 100%;
}
.Table-pay td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 0px 10px;
}
.Table-pay tr:nth-child(even) {
  background-color: #ededed;
}
.Table-pay th {
  background-color: #757575;
  color: white;
  border-color: #757575;
}
.divisor {
  height: 2px;
  background-color: #ededed;
  width: 100%;
  margin: 20px 0px;
}
.react-calendar {
  position: absolute;
  position: fixed;
}
.btn-f-m-l {
  margin-left: 10px !important;
}
.f-r-items {
  display: flex;
  justify-content: flex-end;
}
h3.h3-Noticies {
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #fff;
  display: inline-flex;
  padding: 0px 15px;
  border-radius: 35px;
  color: #295097;
}
h3.h3-Noticies:hover {
  background-color: #ebffb7;
}
h3.h3-Noticies svg {
  margin-right: 8px;
}
.box-search {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  background-color: #ebebeb;
}


.search-btn:hover {
  background-color: #91c446 !important;
}
